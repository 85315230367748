<template>
    <v-container fluid>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Nombre</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="polla_callejera.name" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>



        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Fecha Inicio</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-menu v-model="polla_callejera.menu" :close-on-content-click="false" readonly
                            transition="scale-transition" max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="start_date" v-model="polla_callejera.start_date"
                                    append-icon="mdi-calendar" outlined label="" readonly dense persistent-hint
                                    v-on="on" v-bind="attrs" />
                            </template>
                            <v-date-picker v-model="polla_callejera.date" no-title @input="
    polla_callejera.menu = false;
polla_callejera.start_date = formatDate(polla_callejera.date);
                            " />
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Fecha Fin</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-menu v-model="polla_callejera.menu2" :close-on-content-click="false" readonly
                            transition="scale-transition" max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="end_date" v-model="polla_callejera.end_date"
                                    append-icon="mdi-calendar" outlined label="" readonly dense persistent-hint
                                    v-on="on" v-bind="attrs" />
                            </template>
                            <v-date-picker v-model="polla_callejera.date2" no-title @input="
    polla_callejera.menu2 = false;
polla_callejera.end_date = formatDate(polla_callejera.date2);
                            " />
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Ambito</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-select v-model="polla_callejera.polla_scope_id" :items="polla_scopes" item-value="id"
                            item-text="name" label="" outlined dense />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>




    </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    name: "form-polla-callejera",
    props: ["polla_callejera"],
    watch: {},
    validations() {
        return {
            polla_callejera: {
                name: required,

            },
        };
    },
    data: () => ({
    }),
    computed: {
        ...mapState({
            polla_scopes: (state) => state.util.polla_scopes,
        }),
    },
    methods: {
        ...mapActions(["fetchPollaScopes"]),
        getFetch() {
            this.fetchPollaScopes();
        },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style scoped>
/* .row {
    margin-top: -10px !important;
} */
</style>